<template>
  <button
    class="btn btn-primary"
    :type="typeBtn"
    :disabled="isLoading || isDisabled"
  >
    <VSpinnerForButton
      v-if="isLoading"
      class="btn__spinner"
    />
    <span :class="isLoading && 'transparent'">
      {{ text }}
    </span>
  </button>
</template>

<script>
import VSpinnerForButton from '@/components/views/Spinner/VSpinnerForButton'
export default {
  name: 'ButtonSubmit',
  components: { VSpinnerForButton },
  props: {
    text: {
      type: String,
      required: true
    },
    typeBtn: {
      type: String,
      required: false,
      default: 'submit'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>
