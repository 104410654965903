<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <VProfile
    v-if="!isEdit && !isChangePassword"
    :class="classNameProfile"
    :avatar="getAvatar"
    show-buttons
    @onEdit="goEdit"
    @onChangePassword="changeIsChangePassword"
  >
    <VProfileInfo />
  </VProfile>

  <VProfile
    v-if="!isEdit && isChangePassword"
    :class="classNameProfile"
    show-buttons
    :avatar="getAvatar"
    @onEdit="goEdit"
  >
    <VChangePassword
      @cancel="changeIsChangePassword"
    />
  </VProfile>

  <div v-if="isApplicant && !accreditationIsSend && !isEdit" class="showHint">
    <ButtonSubmit
      v-if="isApplicant && !isEdit"
      text="Получить аккредитацию"
      class="btn-primary--padding btn-link-weight-500 btn-bg-yellow mb-60"
      :is-loading="isLoading"
      :is-disabled="accreditationIsSend"
      @mouseenter="toggleShowHint"
      @mouseleave="toggleShowHint"
      @click="handlerSendAccreditation"
    />

    <transition name="slide-fade">
      <div class="hint" v-if="isHint && accreditationIsSend">
        Ваша заявка в списках на проверку. <br />
        Рассмотрение производится в течение 3 рабочих дней.
      </div>
    </transition>
  </div>

  <div v-if="isApplicant && accreditationIsSend && !isEdit" class="column gap-10 mb-40 p-30 bg--yellow">
    <h4 class="block__subtitle-small">Внимание!</h4>
    <p class="fsz-20 fw-500" style="max-width: 950px">
      Заявка на аккредитацию успешно отправлена. Служба безопасности рассмотрит заявку в&nbsp;течение <b>3х рабочих дней</b>. Вам придет уведомление об аккредитации на электронную почту.
    </p>
  </div>

  <div v-if="!isEdit" class="block__tabs mb-40">
    <VTabs is-small :items="bidderProfile"/>
  </div>

  <router-view/>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import VProfile from '@/components/profile/VProfile'
import VProfileInfo from '@/components/profile/VProfileInfo'
import VTabs from '@/components/views/Tabs/VTabs'
import VChangePassword from '@/components/profile/VChangePassword'
import ButtonSubmit from '@/components/ui/buttons/ButtonSubmit'
import { bidderProfile } from '@/utils/tabs'
import { goAccreditation } from '@/utils/goRoute'
import { useStore } from 'vuex'
import { getAccreditation } from '@/http/accreditationApi'
import { AccreditationController } from '@/controllers/AccreditationController'
import useLoading from '@/use/Base/useLoading'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const isChangePassword = ref(false)
    const accreditationController = new AccreditationController()
    const isHint = ref(false)

    const goEdit = () => {
      router.push({ name: 'bidder-profile-edit', params: { id: store.getters['auth/getUser'].id } })
    }

    const isEdit = computed(() => {
      return router.currentRoute.value.name.includes('edit')
    })

    const isApplicant = computed(() => {
      return store.getters['auth/getUser'].roles?.[0]?.name === 'user' && !isEdit.value
    })

    const classNameProfile = computed(() => {
      return isApplicant.value ? 'mb-30' : 'mb-60'
    })

    const getAvatar = computed(() => {
      return store.getters['auth/getUser']?.avatar?.[0][0].url
    })

    onBeforeMount(async () => {
      const { data } = await getAccreditation(`search[User]=${store.getters['auth/getUser'].id}`)
      store.commit('accreditation/setAccreditation', data[data.length - 1])
    })

    const accreditationIsSend = computed(() => {
      if (!store.getters['accreditation/getAccreditation']) return false
      return store.getters['accreditation/getAccreditation'].status === false && store.getters['accreditation/getAccreditation'].result === false
    })

    const changeIsChangePassword = () => {
      isChangePassword.value = !isChangePassword.value
    }

    const { isLoading, startLoading, finishLoading } = useLoading()
    const handlerSendAccreditation = async () => {
      if (accreditationIsSend.value) return
      try {
        startLoading()
        await accreditationController.applicationAccreditation(store.getters['auth/getUser'].id)
      } finally {
        finishLoading()
      }
    }

    const toggleShowHint = () => {
      isHint.value = !isHint.value
    }

    return {
      isLoading,
      getAvatar,
      isHint,
      toggleShowHint,
      handlerSendAccreditation,
      goEdit,
      isEdit,
      bidderProfile,
      isApplicant,
      classNameProfile,
      goAccreditation,
      changeIsChangePassword,
      isChangePassword,
      accreditationIsSend
    }
  },
  components: {
    ButtonSubmit,
    VProfile,
    VProfileInfo,
    VTabs,
    VChangePassword
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.showHint {
  position: relative;
}
.hint {
  padding: 10px;

  position: absolute;
  left: 250px;
  top: -5px;

  border: 1px solid $color-gray;
  color: $color-gray;
  border-radius: 15px;
}
</style>
